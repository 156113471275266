import React, { Component } from 'react'
import PDFViewer from 'pdf-viewer-reactjs'

class PdfReader extends Component {
    render() {

        return (
            <PDFViewer
                document={{
                    url: this.props.location.state.name,
                }}
            />
        )
    }

}
export default PdfReader;