import React, { Component } from 'react';
import axios from 'axios';

import { Map, GoogleApiWrapper, Circle } from 'google-maps-react';
import Loading from 'react-fullscreen-loading';

class CovidBoardReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            seaPort: [],
            terminal: [],
            recommendations: "",
            title: "",
            covidMarker: [],
            loading : true
        }
    }

    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 0,
    };


    componentDidMount() {
        axios.post(`http://54.188.46.74/api/users/board_report_static`, {
            user_id: '22768'
        }).then(res => {
            this.setState({
                seaPort: Object.values(res.data.data.list_of_seaport_affected),
                terminal: Object.values(res.data.data.list_of_terminal_affected),
                recommendations: res.data.data.recommendations,
                title: res.data.data.title,
                covidMarker: Object.values(res.data.data.markers.covid_marker),
                loading: false
            });
        })
    }

    render() {
        return (
            <div className="content">
                <Loading loading={this.state.loading} background="rgba(0, 0, 0, 0.68)" loaderColor="rgb(102, 244, 236)" />
                <div className="container-fluid  bg-white">
                    <div className="col">
                        <div className="row px-4 py-5">
                            <div className="col-12 my-3">
                                <h4>{this.state.title}</h4>
                            </div>
                            <div className="col-12 my-4">
                                <div style={{ height: '500px', width: '100%' }}>
                                    <Map
                                        initialCenter={{ lat: -21.805149, lng: -49.0921657 }}
                                        google={this.props.google}
                                        style={{ width: '100%', height: '500px', position: 'relative' }}
                                        zoom={3}
                                    >
                                        {
                                            this.state.covidMarker.map((data, index) => (
                                                <Circle
                                                    key={index}
                                                    radius={(15000 * parseInt(data.count))}
                                                    center={{ lat: parseInt(data.lat), lng: parseInt(data.long) }}
                                                    strokeColor='transparent'
                                                    strokeOpacity={0}
                                                    strokeWeight={5}
                                                    fillColor='#FF0000'
                                                    fillOpacity={0.2}
                                                />
                                                // coords = { lat: covidMarker.lat, lng: covidMarker.long }
                                            ))
                                        }

                                    </Map>
                                </div>
                            </div>
                            <div className="col-12 my-4">
                                <h4>Seaports Affected ({this.state.seaPort.length})</h4>
                                <ul className="list-group">
                                    {this.state.seaPort.map((seaPort, index) => (
                                        <li className="list-group-item" key={index}>{seaPort}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-12 my-4">
                                <h4>Terminals Affected ({this.state.terminal.length})</h4>
                                <ul className="list-group">
                                    {this.state.terminal.map((terminal, index) => (
                                        <li className="list-group-item" key={index}>{terminal}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-12 my-3">
                                <div dangerouslySetInnerHTML={{ __html: this.state.recommendations }}></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
export default GoogleApiWrapper({
    apiKey: ("AIzaSyB3vALbyNi26n3Ix5BeImeA8fKUNKqMXcc")
})(CovidBoardReport)