import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class Header extends Component {
    render() {
        return (
            <div>
                <nav className="main-header navbar navbar-expand navbar-dark">
                    <ul className="navbar-nav">
                    <a className="d-block d-sm-none nav-link text-white font-weight-bold" style={{ fontSize: "16px" }} data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
                        <li className="nav-item Rounded_Rectangle_4 d-none d-sm-block">
                            <span>Global Risk Assessment Technologies</span>
                        </li>
                    </ul>
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a className="nav-link text-white" data-widget="control-sidebar" data-slide="true" href="#" role="button">Log Out</a>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle py-0" data-toggle="dropdown" href="#" aria-expanded="false">
                            <span className="header-text text-white">hooman@chtcs.com</span>
                            <img className="img-xs rounded-circle" src="https://sccmt.developing.co/star/images/faces/face1.jpg" alt="Profile image"/>
                             <span className="caret"></span>
                            </a>
                            <div className="dropdown-menu">
                                <Link to="/" className="dropdown-item mt-2">My Dashboard</Link>
                                <Link to="/" className="dropdown-item">Edit Profile</Link>
                                <Link to="/" className="dropdown-item">Edit Company</Link>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        )
    }
}

export default Header;