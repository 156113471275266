import React, { Component } from 'react';


class Ifram extends Component {

    render() {
        return (
            <div className="w-100 vh-100">
                <iframe src={this.props.location.state.name} className="responsive-iframe"></iframe>
            </div>
        )
    }

}

export default Ifram;