import React, { Component } from 'react';


class SupplierReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: true,
            info: false,
            serchList: false
        }
    }
    handleChange(e) {
        this.setState({
            serchList: true
            //form: false,
            //info: true
        })
    }
    handleClick() {
        this.setState({
            form: false,
            info: true,
            serchList: false
        })
    }
    render() {
        let items = [
            { id: 0, value: 'acemedia' }
        ]
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className={this.state.form ? "row d-block" : "row d-none"}>
                        <div className="col-xl-6 col-lg-6 col-md-5 col-sm-6 grid-margin stretch-card">
                            <div className="card card-statistics">
                                <div className="card-body bg-white">
                                    <h3 className="mb-3" style={{ color: "#2292C9" }}>INDIVIDUAL SUPPLIER REPORTS</h3>
                                    <div className="form-group">
                                        <h5 for="tags" className="text-dark">Search for Supplier By Name</h5>
                                        <input type="text" className="form-control" id="tags" placeholder="Search Supplier" onChange={(e) => { this.handleChange(e) }} />
                                        <ul className={this.state.serchList ? "serchList d-block" : "serchList d-none"}>
                                            <li onClick={(e) => { this.handleClick(e) }}>acemedia</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={this.state.info ? "card d-block" : "card d-none"}>
                        <div className="card-body board-report p-4 bg-white">
                            <article className="w-100">
                                <div className="bg">
                                    <div className="clearfix"></div>
                                    <h2 className="mb-0"> acemedia</h2>

                                    <div className="board-report">
                                        <h3 className=" pull-left">INTRODUCTION</h3> <a href="javascript:void(0);" className=" d-block mb-2 text-primary">07/07/2020 10:05AM EDT</a>
                                        <p >
                                            This report is designed to evaluate the risks of slave labor in <strong>Acemedia</strong>'s supply chain activities supporting <strong>Acemedia</strong>. The report is based upon survey data received from <strong>Acemedia</strong> which was collected from the survey filled out as part of the technology platform that is designed specifically for this purpose. This approach represents a comprehensive methodology <strong>Acemedia</strong> has identified to combat slave labor in the supply chain and to continue its efforts in the social responsibility arena.             </p>
                                        <h3 className="text-uppercase">Important Considerations for Improvement</h3>
                                        <p>
                                            Based upon <b>Acemedia</b> answers to the survey questions the technology generates a series of suggestions that can be used as guidance for <b>Acemedia</b> to improve their scores.            </p>

                                        <div className="bg">


                                            <h2 className="text-uppercase">High Priority Considerations</h2>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">By providing specific guidance and/or establishing policies a company will ensure that expectations are defined and execution of requirements are done in line with those expectations.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Require policy to be provided for review.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Request information on what standards are followed.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">By providing specific guidance and/or establishing policies a company will ensure that expectations are defined and execution of requirements are done in line with those expectations.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Require policy to be provided for review.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Request information on what standards are followed.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">By providing specific guidance and/or establishing policies a company will ensure that expectations are defined and execution of requirements are done in line with those expectations.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Require guide be provided for review.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">By providing specific guidance and/or establishing policies a company will ensure that expectations are defined and execution of requirements are done in line with those expectations.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Require it be provided for review.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide proof of ackowledgement by management and staff.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">By providing specific guidance and/or establishing policies a company will ensure that expectations are defined and execution of requirements are done in line with those expectations.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Require policy to be provided for review.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide supplier list for review and determine if there is any history of use within supply chain.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">Security Assessments should be performed on a regular basis to ensure compliance. Some locations may require assessments be completed on a more frequent basis.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide proof of formal security assessments that have been conducted, correction action plans received and documeted evidence of corrective actions taken.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">Training programs ensure that awareness is at the center of the organizations CSR program.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide proof that training sessions are occurring with rosters/sign in sheets and meeting agendas/notes.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">This is useful for pre-assessment activities to ensure once on location auditors can evaluate records and actual worker counts.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Review process should include identification of levels of workforce, i.e. managers, supervisors, workers, etc.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Review facility size to determine feasibility of workforce size.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Determine number of shifts in operations and feasibility based on size of workforce.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">This provides insight as to whether or not the facility owner/management has implemented related best practices.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Obtain and review copies of certifications received.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Determine criterion for re-evaluations / assessments and obtain 3 yr history (if available) of assessments performed, corrective action plans provided and history of actions taken to correct issues identified.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">This provides insight into vulnerabilities related with security standards/requirements. If consolidation occurs product safety concerns will also need to be evaluated.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Evaluate type of production performed and determine capabilities.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Evaluate production type verses internal historical information, industry trends and other publically available information.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">This provides insight as to whether or not the facility owner/management has implemented security related standards/best practices.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Require programs instituted be provided for review.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Request information on what standards are followed.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Require evidence be provided of evaluations or assessments performed evaluating program standards and practices.
</div>
                                            </div>
                                            <h2 className="text-uppercase">Low Priority Considerations</h2>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">Identifies the potential risk of discrimination as well as the risk of exploitation. Disporpotionate is considered greater than 50%. </div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Evaluate numbers provided verses internal historical information, industry trends and other publically available information.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">Identifies the potential risk of discrimination as well as the risk of exploitation. Disproportionate is considered greater than 50%.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Evaluate numbers provided verses internal historical information, industry trends and other publically available information.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">Dislocated workers or refugee's may lack work papers or proper immigration paperwork from the host country. As a result can be more susceptible to coercion or other forms of forced or slave labor.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Request policies regarding onboarding/hiring processes and review for any information related to employee ID's and travel documents.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Obtain employee rosters, with nationality information noted and employment terms, review for any concerns.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>During on-site assessment request sampling of workers present personal ID/travel docs.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Review location history to determine if there is any historical  information related to manufacturers operatin in the area and history of concerns identified.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Evaluate production type verses internal historical information, industry trends and other publically available information.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">By utilizing third party agencies the business may not have access to employee files, work documents, etc. This makes it difficult to evaluate worker status as well as ensure coercion and other methods of forced or slave labor activities does not exist.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide names and points of contact of agencies used.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide copies of contracts /  agreements that are in place with agency.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide recruitment documentation, job descriptions used, proof of advertisements, identify locations of advertisements and samples of advertisements used.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide worker roster of those employees identified as current or past temporary/seasonal workers.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">Substandard or reduced pay may be an indicator that the individual has fallen victim to endentured servitude and/or slave labor. Workers not on payroll or paid through a temporary employment agency are at higher risk for being in a forced or slave labor situation.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Obtain worker schedules, current employee rosters and termination reports and payroll records from trailing 3 year period for comparison.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Evaluate internal historical information, industry trends and other publically available information for these types of activities to determine likelihood.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">By providing specific guidance and/or establishing policies a company will ensure that expectations are defined and execution of requirements are done in line with those expectations.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Require policy to be provided for review.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Request information on what standards are followed.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">By providing specific guidance and/or establishing policies a company will ensure that expectations are defined and execution of requirements are done in line with those expectations.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Require policy to be provided for review.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Request information on what standards are followed.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">By providing specific guidance and/or establishing policies a company will ensure that expectations are defined and execution of requirements are done in line with those expectations.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Require policy to be provided for review.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Request information on what standards are followed.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">By providing specific guidance and/or establishing policies a company will ensure that expectations are defined and execution of requirements are done in line with those expectations.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Require guide be provided for review.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">By providing specific guidance and/or establishing policies a company will ensure that expectations are defined and execution of requirements are done in line with those expectations.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Require it be provided for review.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide proof of ackowledgement by management and staff.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">By providing specific guidance and/or establishing policies a company will ensure that expectations are defined and execution of requirements are done in line with those expectations.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Require policy to be provided for review.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide supplier list for review and determine if there is any history of use within supply chain.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">Security Assessments should be performed on a regular basis to ensure compliance. Some locations may require assessments be completed on a more frequent basis.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide proof of formal security assessments that have been conducted, correction action plans received and documeted evidence of corrective actions taken.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">Training programs ensure that awareness is at the center of the organizations CSR program.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide proof that training sessions are occurring with rosters/sign in sheets and meeting agendas/notes.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">This is useful for pre-assessment activities to ensure once on location auditors can evaluate records and actual worker counts. Also useful in evaluating discrimination, opportunities to exploit, etc.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Evaluate numbers provided verses internal historical information, industry trends and other publically available information.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">This is useful for pre-assessment activities to ensure once on location auditors can evaluate records and actual worker counts. Also useful in evaluating discrimination, opportunities to exploit, etc.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Evaluate numbers provided verses internal historical information, industry trends and other publically available information.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">This is useful for pre-assessment activities to ensure once on location auditors can evaluate records and actual worker counts. Also useful in evaluating discrimination, opportunities to exploit, etc.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Evaluate numbers provided verses internal historical information, industry trends and other publically available information.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">This is useful for pre-assessment activities to ensure once on location auditors can evaluate records and actual worker counts. Also useful in evaluating discrimination, opportunities to exploit, etc.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Evaluate numbers provided verses internal historical information, industry trends and other publically available information.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">This is useful for pre-assessment activities to ensure once on location auditors can evaluate records and actual worker counts. Also useful in evaluating discrimination, opportunities to exploit, etc.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Evaluate numbers provided verses internal historical information, industry trends and other publically available information.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">This is useful for understanding the nature of the business and potential use of temporary workers as well as exposure to affiliated risks.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Evaluate type of production and output against workforce size to determine feasibility.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Evaluate numbers provided verses internal historical information, industry trends and other publically available information.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">Temporary employees should only be utilized to temporarily augment the permanent employee base or should be transitioned to a permanent status after a set period. By only using temporary employees on a permanent basis the opportunity to coerce an employee into a forced labor scenario increases.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide names and points of contact of agencies used.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide copies of contracts /  agreements that are in place with agency.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide recruitment documentation, job descriptions used, proof of advertisements, identify locations of advertisements and samples of advertisements used.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide worker roster of those employees identified as current or past temporary/seasonal workers.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Evaluate production type verses internal historical information, industry trends and other publically available information.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">Migrant workers may lack work papers or proper immigration paperwork from the host country. As a result these workers can be more susceptible to coercion or other forms of forced or slave labor.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide worker roster of those employees identified as displaced, or undocumented or migrant workers.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide copies of contracts /  agreements that are in place with workers identified.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide recruitment documentation or employment agreements in place (if any).
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Evaluate production type verses internal historical information, industry trends and other publically available information.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">Assessment of work conditions, safety practices, etc. as well as who is actually completing the work is difficult to assess when Home Workers are utilized.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide worker roster of those employees identified as home-based workers.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide copies of contracts /  employment agreements that are in place with workers identified.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide recruitment documentation or employment agreements in place (if any).
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Evaluate production type verses internal historical information, industry trends and other publically available information.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Provide policies, procedures or standards used to govern home-based workers.
</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">By utilizing third party suppliers the business may not have access to employee files, work documents, etc. This makes it difficult to evaluate worker status as well as ensure coercion and other methods of forced or slave labor activities does not exist.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Review location history to determine if there is any historical  information related to manufacturers operating in the area and history of concerns identified.</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Review supplier list and determine if there is any history of use of the suppliers identified within supply chain.
</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Are all components manufactured at the same location?</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Determine if location is in an area of concern historically. </div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Evaluate responses against production type to determine feasibility of response. Review available industry data to evaluate validity of response.</div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">Substandard or reduced pay may be an indicator that the individual has fallen victim to endentured servitude and/or slave labor. Workers not on payroll or paid through a temporary employment agency are at higher risk for being in a forced or slave labor situation.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Obtain current payroll records with hourly and salary rates of all workers. Review against available country, regional and industry information. Ensure any subsidies or stipends are taken into account, i.e. housing, meals, etc.</div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Ensure a review and comparison is also performed against non-national workers to ensure wages received are comparable to that of nationals. </div>
                                            </div>
                                            <div className="row" style={{ borderBottom: '1px solid #ccc', padding: '13px 10px' }}>
                                                <div className="supplierDiv">Temporary employees should only be utilized to temporarily augment the permanent employee base or should be transitioned to a permanent status after a set period. By only using temporary employees on a permanent basis the opportunity to coerce an employee into a forced labor scenario increases.</div>

                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Obtain current employee rosters and termination reports as well as payroll records from trailing 3 year period for  comparison. </div>
                                                <div className="col-12" style={{ position: 'relative' }}><i className="fa fa-circle" style={{ marginRight: '5px' }}></i>Review dates of employment, employment status and through comparison identify any reoccuring "re-hire" scenarios.</div>
                                            </div>
                                        </div>

                                        <h3>SCORING EXPLANATION</h3>

                                        <div>
                                            <div className="box clearfix">
                                                <div className="box-heading">CHTCS Proprietary Index for Industry Risk</div>
                                                <div style={{ padding: "10px", lineHeight: "30px" }}>

                                                    <font style={{ marginBottom: '10px', display: 'block' }}>The Global Risk Assessment Technology (“GRAT) provides our customers with a method to collect information pertaining to the over 100 factors identified by the CHTCS experts necessary to anticipate and evaluate the risk of slave labor and human trafficking in the supply chain. Our experts have collectively over 50 years of experience in this area and have collaborated to identify the material factors. The responses from the suppliers are provided via a point and click technology to allow for ease of participation in the program.</font>


                                                    <font style={{ marginBottom: '10px', display: 'block' }}>The CHTCS experts gather independent data from many sources including without limitation, direct observations, other available databases, other internet based technologies, interviews of many different categories of individual profiles, and government sources. This independent data gathering is ongoing and may impact scoring an any time. Changes in the algorithms can be made immediately as the data continues to improve. </font>

                                                    <font style={{ marginBottom: '10px', display: 'block' }}>Our mathematicians developed mathematical algorithms that utilize the factors we developed and incorporate our experts’ observations and analysis in order to develop a scoring system which guides our customers and assists in evaluating the risks of slave labor and human trafficking in the supply chain. The scoring system is simple to understand.</font>
                                                    <div className="col-12 ">
                                                        <img src="https://grat.chtcs.com/img/users/1566830878_grat_risk_index_graphic.png" width="40%" />
                                                    </div>
                                                    <div className="clearfix"></div>
                                                    <font style={{ marginBottom: '10px', display: 'block' }}>CHTCS will continually improve the scoring and allow for better pattern recognition.</font>
                                                </div>
                                            </div>
                                        </div>

                                        <p className="break"></p>
                                        <h3>COMBINED SCORE</h3>
                                        <div className="im-score col-md-12">
                                            <div className="div1-small">
                                                <div className="div2">
                                                    <div className="clearfix div3">
                                                        <div className="heading1">Combined Score</div>
                                                        <img className="scrimg" src="https://grat.chtcs.com/img/chtcs_new_scoring_containers_purple.png" />
                                                        <div className="percentage1 text-center" style={{ color: "#7C6CCD" }}>43.00<span>score</span></div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p>
                                            <b>acemedia</b>'s combined CHTCS SCORE IS <strong>43.00  (MODERATE)</strong>.
                The Combined Factors Score constitutes the combined mean score of each factor category.
                The overall score is translated into a percentile grade of 0-100 equivalent to a passing or needs improvement.            </p>
                                        <p>
                                            As <b>Acemedia</b> considers its continuing efforts in mitigating the risk of
                slave labor in the supply chain, it is important to consider the factors
                that drive the CHTCS COMBINED SCORE. Specifically, there are five category
                scores that make up the combined score. <b>Acemedia</b>'s results in these
                categories are show below.            </p>

                                        <h3>GEOGRAPHIC SCORE</h3>
                                        <div className="im-score col-md-12">
                                            <div className="div1-small">
                                                <div className="div2">
                                                    <div className="clearfix div3">
                                                        <div className="heading1">Geographic Score</div>
                                                        <img className="scrimg" src="https://grat.chtcs.com/img/chtcs_new_scoring_containers_blue.png" />
                                                        <div className="percentage1 text-center" style={{ color: '#0091CC' }}>0.00<span>score</span></div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p>
                                            <b>The Supplier</b> scored <strong>0.00 (LOWEST)</strong> in the Geographic
                category. This reporting category measures risks associated with the geographic attributes of the organizations manufacturing and/or supply chain operations.
            </p>

                                        <h3>DEMOGRAPHIC SCORE</h3>
                                        <div className="im-score col-md-12">
                                            <div className="div1-small">
                                                <div className="div2">
                                                    <div className="clearfix div3" >
                                                        <div className="heading1">Demographic Score</div>
                                                        <img className="scrimg" src="https://grat.chtcs.com/img/chtcs_new_scoring_containers_purple.png" />
                                                        <div className="percentage1 text-center" style={{ color: '#7C6CCD' }}>59.29<span>score</span></div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p>
                                            <b>The Supplier</b> scored <strong>59.29  (MODERATE)</strong> in the Demographic
                category. This reporting category provides the ability to evaluate the demographics of the organizations workforce in
                order to identify potential risks associated with hiring practices, personnel turn, and personnel health and safety.

            </p>
                                        <p className="break"></p>
                                        <h3>SCOPE AND SCALE SCORE</h3>
                                        <div className="im-score col-md-12">
                                            <div className="div1-small">
                                                <div className="div2">
                                                    <div className="clearfix div3" >
                                                        <div className="heading1">Scope and Scale Score</div>
                                                        <img className="scrimg" src="https://grat.chtcs.com/img/chtcs_new_scoring_containers_green.png" />
                                                        <div className="percentage1 text-center" style={{ color: '#009a6' }}>25.90<span>score</span></div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p>
                                            <b>The Supplier</b> scored <strong>25.90  (LOW TO MODERATE)</strong> in the Scope and Scale category. This reporting category provides insights and an understanding of the scope and scale of the organizations manufacturing and supply chain operations.
            </p>

                                        <h3>STANDARDS AND PRACTICES SCORE</h3>
                                        <div className="im-score col-md-12">
                                            <div className="div1-small">
                                                <div className="div2">
                                                    <div className="clearfix div3" >
                                                        <div className="heading1">Standards and Practices Score</div>
                                                        <img className="scrimg" src="https://grat.chtcs.com/img/chtcs_new_scoring_containers_green.png" />
                                                        <div className="percentage1 text-center" style={{ color: '#009a6' }}>19.47<span>score</span></div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p>
                                            <b>Acemedia</b> scored <strong>19.47  (LOW TO MODERATE)</strong> in the Standards
                and Practices category. This reporting category supports the identification of the organizations standards and practices
                involving manufacturing and supply chain operations and processes in order to measures effectiveness.
                    .
            </p>

                                        <h3>WORKFORCE CONSTRUCTION SCORE</h3>
                                        <div className="im-score col-md-12">
                                            <div className="div1-small">
                                                <div className="div2">
                                                    <div className="clearfix div3" >
                                                        <div className="heading1">Workforce Construction Score</div>
                                                        <img className="scrimg" src="https://grat.chtcs.com/img/chtcs_new_scoring_containers_yellow.png" />
                                                        <div className="percentage1 text-center" style={{ color: '#FCD333' }}>73.77<span>score</span></div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p>
                                            <b>Acemedia</b> scored <strong>73.77  (ELEVATED)</strong> in the Geographic
                category. This reporting category provides the ability to evaluate the workforce construction of the organization to better understand what challenges and risks may exist.
                    .
            </p>
                                        <p>
                                            The above five categories are the components of the CHTCS Combined Score.            </p>

                                        <h3>INDUSTRY TYPE CONSIDERATIONS</h3>

                                        <p>
                                            The CHTCS protocol also provides a separate index for the composition of
                                            the industry types for all suppliers in the database. The scale for this
                                            score runs from 40 (the more challenging industry types) to 1 (the least
                challenging industry types). This index is intended to give <b>Acemedia</b> an idea of how challenging their industry is with respect to slave labor. These scores are not factored into the CHTCS proprietary risk index.            </p>
                                        <p>

                                            The Supplier score for the Industry Type consideration is <strong>1.00</strong>            </p>
                                        <p>
                                            <a href="javascript:void(0);" rel="22505" className="open-supplier-modal no-print  btn btn-primary" >Link to Surveys</a>
                                        </p>

                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default SupplierReport;