import React, { Component } from 'react';

import Left from './Left';
import Header from './Header';
class Wrapper extends Component {
    render() {
        return (
            <div>
                <Header />
                <Left />
            </div>
        )
    }
}
export default Wrapper;