import React from 'react';


class ClientSpecific extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            All: true,
            Mexico: false,
            name: "All Reports"
        }
    }

    render() {
        const handleAll = event => {
            this.setState({
                All: true,
                Mexico: false,
                name: "All Reports"
            })
        }
        const handleMexico = event => {
            this.setState({
                Mexico: true,
                All: false,
                name: "Mexico"
            })
        }
        const handleClick = event => {
            event.preventDefault();
        }
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-5 col-sm-6 grid-margin stretch-card">
                            <div className="card card-statistics">
                                <div className="card-header  blue-card-header">Intelligence & Research for ShopMart</div>
                                <div className="card-body listType">
                                    <ul className="pl-4">
                                        <li>This dashboard will navigate you to other platforms within the Global Risk Assessment Technologies Platform.</li>
                                        <li>Get started by launching a specific GRAT platform to the right or by engaging with the maps, intelligence & research below.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-5 col-sm-6 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-header blue-card-header">Categories Covered</div>
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-sm-12">
                                            <div className="chip mt-2 mb-0 waves-effect">
                                                Agriculture  <i className="close fas fa-times"></i>
                                            </div>
                                            <div className="chip mt-2 mb-0 waves-effect">
                                                Farming  <i className="close fas fa-times"></i>
                                            </div>
                                            <div className="chip mt-2 mb-0 waves-effect">
                                                Weather  <i className="close fas fa-times"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-5 col-sm-6 grid-margin stretch-card">
                            <div className="card card-statistics">
                                <div className="card-header  blue-card-header">Agricultural Weather Insights</div>
                                <div className="card-body listType">
                                    <img src="./images/mexico_agriculture_report_graphic.png" className="w-100 mb-4" />
                                    <div className="form-group">
                                        <div className="dropdown">
                                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {this.state.name}
                                            </button>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a className="dropdown-item" onClick={handleAll}>All Reports</a>
                                                <a className="dropdown-item" onClick={handleMexico}>Mexico</a>
                                                <a className="dropdown-item" href=""onClick={handleClick}>Central America</a>
                                                <a className="dropdown-item" onClick={handleClick}>Ghana</a>
                                                <a className="dropdown-item" onClick={handleClick}>West Africa</a>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.All ? (
                                        <ul className="pl-4 arrowsList">
                                            <li>
                                                <a href="./files/20200714 Mexico Ag Weather.pdf" target="_blank">Mexico Agricultural Weather Report - <b>July 2020</b></a>
                                            </li>
                                            <li>Central America Agricultural Weather Report - <b>July 2020</b></li>
                                            <li>Ghana Agricultural Weather Report - <b>July 2020</b></li>
                                            <li>West Africa Agricultural Weather Report - <b>July 2020</b></li>
                                            <li>Mexico Agricultural Weather Report - <b>June 2020</b></li>
                                            <li>Central America Agricultural Weather Report - <b>June 2020</b></li>
                                            <li>Ghana Agricultural Weather Report - <b>June 2020</b></li>
                                            <li>West Africa Agricultural Weather Report - <b>June 2020</b></li>
                                        </ul>
                                    ) : (
                                            <ul className="pl-4 arrowsList">
                                                <li>
                                                    <a href="./files/20200714 Mexico Ag Weather.pdf" target="_blank">Mexico Agricultural Weather Report - <b>July 2020</b></a>
                                                </li>
                                                <li>Mexico Agricultural Weather Report - <b>June 2020</b></li>
                                                <li>Mexico Agricultural Weather Report - <b>May  2020</b></li>
                                                <li>Mexico Agricultural Weather Report - <b>April  2020</b></li>
                                                <li>Mexico Agricultural Weather Report - <b>March  2020</b></li>
                                                <li>Mexico Agricultural Weather Report - <b>Feb  2020</b></li>

                                            </ul>
                                        )}


                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-5 col-sm-6 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-header blue-card-header">Climate Refugee Routes</div>
                                <div className="card-body listType">
                                    <img src="./images/climate_refugee_routes.png" className="w-100  mb-4" />
                                    <div className="form-group">
                                        <div className="dropdown">
                                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            All Reports
                                            </button>
                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a className="dropdown-item" onClick={handleClick}>All Reports</a>
                                                <a className="dropdown-item" onClick={handleClick}>Mexico</a>
                                                <a className="dropdown-item" onClick={handleClick}>Central America</a>
                                                <a className="dropdown-item" onClick={handleClick}>Ghana</a>
                                                <a className="dropdown-item" onClick={handleClick}>West Africa</a>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="pl-4 arrowsList">
                                        <li>
                                            <a href="./files/1566686869_Climage_Refugee_Routes (3).jpeg" target="_blank">Mexico Climate Refugee Routes Report - July 2020</a>
                                        </li>
                                        <li>Central America Climate Refugee Routes Report - <b>July 2020</b></li>
                                        <li>Ghana Climate Refugee Routes Report - <b>July 2020</b></li>
                                        <li>West Climate Refugee Routes Report - <b>July 2020</b></li>
                                        <li>Mexico Climate Refugee Routes Report - <b>June 2020</b></li>
                                        <li>Central America Climate Refugee Routes Report - <b>June 2020</b></li>
                                        <li>Ghana Climate Refugee Routes Report - <b>June 2020</b></li>
                                        <li>West Africa Climate Refugee Routes Report - <b>June 2020</b></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ClientSpecific;