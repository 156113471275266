import React, { Component,useState  } from 'react';
import PageTitle from '../../Common/PageTitle';
import { Donut } from 'react-dial-knob';

class Inline extends Component {
    render() {
        return (
            <div>
                <PageTitle
                    title='Inline'
                />
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card card-danger">
                                    <div className="card-header">
                                        <h3 className="card-title">Donut</h3>

                                    </div>
                                    <div className="card-body">
                                        <Donut
                                            diameter={200}
                                            min={10}
                                            max={100}
                                            step={1}
                                            value={25}
                                            theme={{
                                                activeColor: 'red',
                                                defaultColor: 'black'
                                            }}

                                            ariaLabelledBy={'my-label'}
                                        ></Donut>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}

export default Inline;