import React, { Component } from 'react';
import { Link } from 'react-router-dom';



class Left extends Component {
    render() {
        return (
            <div>
                <aside className="main-sidebar sidebar-dark-primary leftBar elevation-4">

                    <div className="sidebar">
                        <div className="user-panel py-2 d-flex">
                            <div className="dropdown w-100 mt-1">
                                <button className="btn btn-outline-dark btn-sm btn-block dropdown-toggle text-white" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span className="flag-icon flag-icon-us"></span> English
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {/* <a className="dropdown-item" href="#">Action</a> */}
                                </div>
                            </div>
                            <div className="text-right d-none d-sm-block">
                                <a className="nav-link text-white font-weight-bold" style={{ fontSize: "16px" }} data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
                            </div>
                        </div>
                        <nav className="mt-2">
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                <li className="nav-item has-treeview menu-open">
                                    <Link to="/" className="nav-link ">
                                        <i className="nav-icon fas fa-tachometer-alt"></i>
                                        <p>Dashboard</p>
                                    </Link>

                                </li>
                                <li className="nav-item has-treeview">
                                    <a href="#" className="nav-link">
                                        <i className="nav-icon far fa-newspaper"></i>
                                        <p> Get Reports<i className="right fas fa-angle-right"></i></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <Link to="/board-report" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Board Report</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/suppliers-report" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Supplier Reports</p>
                                            </Link>
                                        </li>
                                        {/* <li className="nav-item">
                                            <Link to="/board" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Covid Board Reports</p>
                                            </Link>
                                        </li> */}
                                        {/* <li className="nav-item">
                                            <Link to="/chart" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>ChartJS</p>
                                            </Link>

                                        </li>

                                        <li className="nav-item">
                                            <Link to="/inline" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Inline</p>
                                            </Link>
                                        </li> */}
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </aside>
            </div>
        )
    }
}

export default Left;