import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import PdfReader from '../Elements/PdfReader';
import Gmap from '../Elements/Gmap';
import Dashboard from '../Pages/Dashboard';
import Chart from '../Pages/Chart';
import Inline from '../Pages/Inline';
import Ifram from '../Pages/Ifram';
import SupplierReport from '../Pages/SupplierReport';
import BoardReport from '../Pages/BoardReport';
import CovidBoardReport from '../Pages/CovidBoardReport';
import ClientSpecific from '../Pages/ClientSpecific';
class Routers extends Component {
    render() {
        return (
            <div>
                <Route
                    exact={true}
                    path="/"
                    component={Dashboard}
                />
                 <Route
                    exact={true}
                    path="/chart"
                    component={Chart}
                />
                  <Route
                    exact={true}
                    path="/inline"
                    component={Inline}
                />
                 <Route
                    exact={true}
                    path="/map"
                    component={Ifram}
                />
                 <Route
                    exact={true}
                    path="/file-reader"
                    component={PdfReader}
                />
                 <Route
                    exact={true}
                    path="/board-report"
                    component={BoardReport}
                />
                 <Route
                    exact={true}
                    path="/suppliers-report"
                    component={SupplierReport}
                />
                 <Route
                    exact={true}
                    path="/board"
                    component={CovidBoardReport}
                />
                 <Route
                    exact={true}
                    path="/g-map"
                    component={Gmap}
                />
                 <Route
                    exact={true}
                    path="/client-specific"
                    component={ClientSpecific}
                />
            </div>
        )
    }
}

export default Routers;