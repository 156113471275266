import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Circle } from 'google-maps-react';
import axios from 'axios';
import Loading from 'react-fullscreen-loading';
class Gmap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            covidMarker: [],
            loading: true
        }
    }

    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 0,
    };

    componentDidMount() {
        axios.post(`http://54.188.46.74/api/users/board_report_static`, {
            user_id: '22768'
        }).then(res => {
            this.setState({
                covidMarker: Object.values(res.data.data.markers.covid_marker),
                loading: false
            });
        })
    }

    render() {
        return (
            <div className="content">
                
                <div className="container-fluid">
                    <div className="col">
                        <div style={{ height: '100vh', width: '100%' }}>
                            <Map
                                initialCenter={{ lat: -21.805149, lng: -49.0921657 }}
                                google={this.props.google}
                                style={{ width: '100%', height: '100vh', position: 'relative' }}
                                zoom={3}
                            >
                                {
                                    this.state.covidMarker.map((data, index) => (
                                        <Circle
                                            key={index}
                                            radius={(15000 * parseInt(data.count))}
                                            center={{ lat: parseInt(data.lat), lng: parseInt(data.long) }}
                                            strokeColor='transparent'
                                            strokeOpacity={0}
                                            strokeWeight={5}
                                            fillColor='#FF0000'
                                            fillOpacity={0.2}
                                        />
                                        // coords = { lat: covidMarker.lat, lng: covidMarker.long }
                                    ))
                                }

                            </Map>
                        </div>
                    </div>
                </div>
                <Loading loading={this.state.loading} background="rgba(0, 0, 0, 0.68)" loaderColor="rgb(102, 244, 236)" />
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyB3vALbyNi26n3Ix5BeImeA8fKUNKqMXcc')
})(Gmap);