import React from 'react';
import Wrapper from './Common/Wrapper';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Routers from './Components/Routers/Routers';

function App() {
  return (
    <div className="wrapper">
      <Router>
        <Wrapper />
        <div className="content-wrapper">
          <Routers />
        </div>
      </Router>
    </div>
  );
}

export default App;
