import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';

const data = {
    labels: [
        'LOWEST',
        'LOW TO MODERATE',
        'MODERATE',
        'ELEVATED',
        'MORE ELEVATED',
    ],
    datasets: [{
        data: [300, 50, 100, 125, 250],
        backgroundColor: [
            '#0091CC',
            '#009A66',
            '#7C6CCD',
            '#FCD333',
            '#F0742F',
        ],
        hoverBackgroundColor: [
            '#0091CC',
            '#009A66',
            '#7C6CCD',
            '#FCD333',
            '#F0742F',
        ]
    }]
};
const data2 = {
    labels: [
        'LOWEST',
        'LOW TO MODERATE',
        'MODERATE',
        'ELEVATED',
        'MORE ELEVATED',
    ],
    datasets: [{
        data: [0, 150, 300, 0, 0],
        backgroundColor: [
            '#0091CC',
            '#009A66',
            '#7C6CCD',
            '#FCD333',
            '#F0742F',
        ],
        hoverBackgroundColor: [
            '#0091CC',
            '#009A66',
            '#7C6CCD',
            '#FCD333',
            '#F0742F',
        ]
    }]
};
const data3 = {
    labels: [
        'LOWEST',
        'LOW TO MODERATE',
        'MODERATE',
        'ELEVATED',
        'MORE ELEVATED',
    ],
    datasets: [{
        data: [20, 150, 300, 50, 99],
        backgroundColor: [
            '#0091CC',
            '#009A66',
            '#7C6CCD',
            '#FCD333',
            '#F0742F',
        ],
        hoverBackgroundColor: [
            '#0091CC',
            '#009A66',
            '#7C6CCD',
            '#FCD333',
            '#F0742F',
        ]
    }]
};
class BoardReport extends Component {
    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="card " >
                        <div className="card-body board-report p-4 bg-white">
                            <h1 className="heading">Tyson International</h1>
                            <h3>INTRODUCTION</h3>
                            <p>
                                This report is designed to evaluate the risks of slave labor in <strong>Tyson International's</strong> supply chain. The report is based upon survey data received
                from <b>Tyson International</b>'s suppliers that <strong>1498</strong> participated in including their data into the technology
                platform that is designed specifically for this purpose. This approach represents a comprehensive methodology <b>Tyson International</b> has identified to combat
                slave labor in the supply chain and continue its efforts in the social responsibility arena.
                 </p>
                            <h3>SCORING EXPLANATION</h3>
                            <div style={{ pageBreakAfter: 'always' }}>
                                <div className="box clearfix">
                                    <div className="box-heading">CHTCS Proprietary Index for Industry Risk</div>
                                    <div style={{ padding: '10px', lineHeight: '30px' }}>
                                        <font style={{ marginBottom: '10px', display: 'block' }}>The Global Risk Assessment Technology (“GRAT) provides our customers with a method to collect information pertaining to the over 100 factors identified by the CHTCS experts necessary to anticipate and evaluate the risk of slave labor and human trafficking in the supply chain. Our experts have collectively over 50 years of experience in this area and have collaborated to identify the material factors. The responses from the suppliers are provided via a point and click technology to allow for ease of participation in the program.</font>
                                        <font style={{ marginBottom: '10px', display: 'block' }}>The CHTCS experts gather independent data from many sources including without limitation, direct observations, other available databases, other internet based technologies, interviews of many different categories of individual profiles, and government sources. This independent data gathering is ongoing and may impact scoring an any time. Changes in the algorithms can be made immediately as the data continues to improve. </font>
                                        <font style={{ marginBottom: '10px', display: 'block' }}>Our mathematicians developed mathematical algorithms that utilize the factors we developed and incorporate our experts’ observations and analysis in order to develop a scoring system which guides our customers and assists in evaluating the risks of slave labor and human trafficking in the supply chain. The scoring system is simple to understand.</font>
                                        <div className="col-xs-12 ">
                                            <img src="https://grat.chtcs.com/img/users/1566830878_grat_risk_index_graphic.png" width="40%" />
                                        </div>
                                        <div className="clearfix"></div>
                                        <font style={{ marginBottom: '10px', display: 'block' }}>CHTCS will continually improve the scoring and allow for better pattern recognition.</font>
                                    </div>
                                </div>
                            </div>

                            <h3>COMBINED SCORE</h3>
                            <p>
                                <b>Tyson International</b>'s
                                 combined CHTCS SCORE IS
                                 <strong> 42.31  (MODERATE)</strong>.
                The Combined Factors Score constitutes the combined mean score of each factor category.
                The overall score is translated into a percentile grade of 0-100 equivalent to a passing or needs improvement.
                        </p>
                            <div className="row">
                                <div className="col-sm-4 im-score">
                                    <div className="div1-small">
                                        <div className="div2">
                                            <div className="clearfix div3">
                                                <div className="heading1">Combined Score</div>
                                                <img className="scrimg" src="https://grat.chtcs.com/img/chtcs_new_scoring_containers_purple.png" />
                                                <div className="percentage1 text-center">42.31<span>score</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-8">
                                    <Pie data={data} />
                                </div>
                            </div>
                            <p>
                                As <b>Tyson International</b> considers its continuing efforts in mitigating the risk of
                slave labor in the supply chain, it is important to consider the factors
                that drive the CHTCS COMBINED SCORE. Specifically, there are five category
                scores that make up the combined score. <b>Tyson International</b>'s results in these
                categories are show below.
                 </p>
                            <h3>GEOGRAPHIC SCORE</h3>
                            <p>
                                <b>Tyson International</b> scored <strong>49.83 (MODERATE)</strong> in the Geographic
                category. This reporting category measures risks associated with the geographic attributes of the organizations
                manufacturing and/or supply chain operations.
                </p>
                            <div className="row">
                                <div className="col-sm-4 im-score">
                                    <div className="div1-small">
                                        <div className="div2">
                                            <div className="clearfix div3">
                                                <div className="heading1">Combined Score</div>
                                                <img className="scrimg" src="https://grat.chtcs.com/img/chtcs_new_scoring_containers_purple.png" />
                                                <div className="percentage1 text-center">40.29<span>score</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-8">
                                    <Pie data={data2} />
                                </div>
                            </div>
                            <h3>WORKFORCE CONSTRUCTION SCORE</h3>
                            <p>
                                <b>Tyson International</b> scored <strong>43.40 (MODERATE)</strong> in the Geographic
                category. This reporting category provides the ability to evaluate the workforce construction of the organization to better understand what challenges and risks may exist.
                    .     </p>
                            <div className="row">
                                <div className="col-sm-4 im-score">
                                    <div className="div1-small">
                                        <div className="div2">
                                            <div className="clearfix div3">
                                                <div className="heading1">Combined Score</div>
                                                <img className="scrimg" src="https://grat.chtcs.com/img/chtcs_new_scoring_containers_purple.png" />
                                                <div className="percentage1 text-center">43.40<span>score</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-8">
                                    <Pie data={data3} />
                                </div>
                            </div>
                            <p>
                                <small> The above five categories are the components of the CHTCS Combined Score.</small>
                            </p>
                            <h3>INDUSTRY TYPE CONSIDERATIONS</h3>
                            <p>
                                CHTCS has developed a separate proprietary index for the composition of the industry types for all suppliers in the database. The scale for this score runs from 40 (the more challenging industry types) to 1 (the least challenging industry types). This index is intended to give the viewer a very high level view of how many of their suppliers are in the industries that are more or less likely to have slave labor concerns. In general, there is nothing that <b>Tyson International</b> can do about this score. These scores are not factored into the CHTCS proprietary risk index. It is only intended to provide a backdrop of how challenging the suppliers’ industry types are.</p>
                            <p>
                                <b>Tyson International</b> had <strong>1498</strong> suppliers
                participate in their database.
                    .     </p>
                            <p>
                                The average industry type score is <strong>16.00</strong>.
</p>

                            <p>
                                The median industry type score is <strong>17.47</strong>.
</p>
                            <h3>OVERALL CONSIDERATIONS AND PROTOCOL FOR FURTHER REVIEW</h3>
                            <p>
                                REVIEWING SUPPLIERS THAT HAVE SCORED THE HIGHEST IN THE VARIOUS CATEGORIES:</p>
                            <p>
                                The suppliers who scored in the bottom one percent of the CHTCS combined
                                scores as well as each category score have been identified. There are
                <b>15 suppliers</b> in the
                bottom one percent in each category. This does not necessarily mean that
                these suppliers are not qualified. Instead, <b>Tyson International</b>, can if it chooses,
                examine why these suppliers have such scores and determine how <b>Tyson International</b>
                takes this information into account as it continues to reduce the risk of
                slave labor in the supply chain.</p>
                            <p>
                                A specific supplier review can be accomplished through the technology
                                platform with a drill down on all the scores listed above as well as the
                                factors that support such scores.
</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default BoardReport;