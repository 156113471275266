import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import { Donut } from 'react-dial-knob'
class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Supply: true,
            Facilities: false,
            Seaports: false,
            Terminals: false,
            TIPs: false
        }
    }


    onSubmit(e) {
        e.preventDefault();
        document.getElementById('onClose').click();
        alert('Thank you!')

    }

    render() {

        const handleFacilities = event => {
            this.setState({
                Facilities: true
            })
        }
        const handleSeaports = event => {
            this.setState({
                Seaports: true
            })
        }
        const handleTerminals = event => {
            this.setState({
                Terminals: true
            })
        }
        const handleTIPs = event => {
            this.setState({
                TIPs: true
            })
        }
        const handleSupply = event => {
            this.setState({
                Supply: true
            })
        }

        const handleClick = event => {
            event.preventDefault();
        }
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-5 col-sm-6 grid-margin stretch-card">
                            <div className="card card-statistics">
                                <div className="card-header  blue-card-header">Welcome to your Tyson International Dashboard</div>
                                <div className="card-body listType">
                                    <ul className="pl-4">
                                        <li>This dashboard will navigate you to other platforms within the Global Risk Assessment Technologies Platform.</li>
                                        <li>Get started by launching a specific GRAT platform to the right or by engaging with the maps, intelligence & research below.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-5 col-sm-6 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-header blue-card-header">GRAT Platforms</div>
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-sm-3 col-md-4 col-lg-5">
                                            <a className="btn-block btnBlue text-left" href="http://counterslavelabor.developing.co/" target="_blank">GRAT <b>Counter Slave Labor</b></a>
                                            <a className="btn-block btnWhite text-left text-dark" data-target="#modal-default" data-toggle="modal">GRAT <b>Custom Solutions</b></a>
                                        </div>
                                        <div className="col-sm-9 col-md-8 col-lg-7 ">
                                            <p style={{ fontSize: "13px" }}>Select a platform to the left to quickly access key reports or to launch that platforms’ standalone application. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-8 grid-margin stretch-card">
                            <div className="card card-primary card-tabs bg-transparent">
                                <div className="card-header p-0 pt-0 headColor position-relative">
                                    <ul className="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                                        <li className="nav-item">
                                            <a onClick={handleSupply} className="radiusLeft nav-link active" id="custom-tabs-one-home-tab" data-toggle="pill" href="#custom-tabs-one-home" role="tab" aria-controls="custom-tabs-one-home" aria-selected="true">Supply Chain</a>
                                        </li>
                                        <li className="nav-item">
                                            <a onClick={handleFacilities} className="nav-link" id="custom-tabs-one-facility-tab" data-toggle="pill" href="#custom-tabs-one-facility" role="tab" aria-controls="custom-tabs-one-facility" aria-selected="false">Facilities</a>
                                        </li>
                                        <li className="nav-item">
                                            <a onClick={handleSeaports} className="nav-link" id="custom-tabs-one-seaports-tab" data-toggle="pill" href="#custom-tabs-one-seaports" role="tab" aria-controls="custom-tabs-one-seaports" aria-selected="false">Seaports</a>
                                        </li>
                                        <li className="nav-item">
                                            <a onClick={handleTerminals} className="nav-link" id="custom-tabs-one-terminals-tab" data-toggle="pill" href="#custom-tabs-one-terminals" role="tab" aria-controls="custom-tabs-one-terminals" aria-selected="false">Terminals</a>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" id="custom-tabs-one-settings-tab" to="/g-map">COVID-19</Link>
                                        </li>
                                        <li className="nav-item">
                                            <a onClick={handleTIPs} className="radiusRight nav-link" id="custom-tabs-one-TIPs-tab" data-toggle="pill" href="#custom-tabs-one-TIPs" role="tab" aria-controls="custom-tabs-one-TIPs" aria-selected="false">TIPs</a>
                                        </li>
                                    </ul>
                                    <Link
                                        className="position-absolute" style={{ position: "absolute", right: "10px", top: "8px" }}
                                        to={{
                                            pathname: "map",
                                            state: {
                                                name: "https://sccmt.developing.co/users/map"
                                            }
                                        }}><i className="fas fa-expand"></i></Link>
                                </div>
                                <div className="card-body  p-0">
                                    <div className="tab-content" id="custom-tabs-one-tabContent">
                                        <div className="tab-pane fade show active" id="custom-tabs-one-home" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
                                            {this.state.Supply ? <iframe src="https://sccmt.developing.co/users/map" className="responsive-iframe" style={{ height: "500px" }}></iframe> : ""}
                                        </div>
                                        <div className="tab-pane fade" id="custom-tabs-one-facility" role="tabpanel" aria-labelledby="custom-tabs-one-facility-tab">
                                            {this.state.Facilities ? <iframe src="https://sccmt.developing.co/users/map?map_type=facility" className="responsive-iframe" style={{ height: "500px" }}></iframe> : ""}
                                        </div>
                                        <div className="tab-pane fade" id="custom-tabs-one-seaports" role="tabpanel" aria-labelledby="custom-tabs-one-seaports-tab">
                                            {this.state.Seaports ? <iframe src="https://sccmt.developing.co/users/map?map_type=seaports" className="responsive-iframe" style={{ height: "500px" }}></iframe> : ""}
                                        </div>
                                        <div className="tab-pane fade" id="custom-tabs-one-terminals" role="tabpanel" aria-labelledby="custom-tabs-one-terminals-tab">
                                            {this.state.Terminals ? <iframe src="https://sccmt.developing.co/users/map?map_type=terminals" className="responsive-iframe" style={{ height: "500px" }}></iframe> : ""}
                                        </div>
                                        <div className="tab-pane fade" id="custom-tabs-one-TIPs" role="tabpanel" aria-labelledby="custom-tabs-one-TIPs-tab">
                                            {this.state.TIPs ? <img src="./screencapture-counterslavelabor-developing-co-2020-07-08-21_23_53.png" width="100%" height="500px" /> : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4  col-sm-4 grid-margin stretch-card">
                            <div className="card card-statistics">
                                <div className="card-header  blue-card-header">Survey Statuses</div>
                                <div className="card-body listType">
                                    <div className="mb-4">
                                        <ul className="pl-4 arrowsList">
                                            <li><b>Counter Slave Labor Surveys</b></li>
                                        </ul>
                                        <div className="row mb-4">
                                            <div className="col-6 col-md-3 text-center">
                                                <Donut
                                                    diameter={80}
                                                    min={0}
                                                    max={100}
                                                    step={1}
                                                    value={80}
                                                    onValueChange=""
                                                    theme={{
                                                        donutColor: '#db9a36',
                                                        donutThickness: "15"
                                                    }}
                                                >
                                                </Donut>

                                            </div>
                                            <div className="col-sm-8 text-left">
                                                <p><small>View or Edit Tyson International Survey Answers</small></p>
                                                <a style={{ color: '#000000', backgroundColor: '#68cdf1' }} className="px-2 py-1 rounded" href="http://counterslavelabor.developing.co/users/dashboard2?report=1" target="_blank">Full Report</a>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 col-md-3 text-center">
                                                <Donut
                                                    diameter={80}
                                                    min={0}
                                                    max={100}
                                                    step={1}
                                                    value={77}
                                                    onValueChange=""
                                                    theme={{
                                                        donutColor: '#50b9f9',
                                                        donutThickness: "15"
                                                    }}
                                                >
                                                </Donut>

                                            </div>
                                            <div className="col-sm-8 text-left">
                                                <p><small>View Supplier Survey Answers</small></p>
                                                <a style={{ color: '#000000', backgroundColor: '#68cdf1' }} className="px-2 py-1 rounded" href="http://counterslavelabor.developing.co/users/dashboard2?report=2" target="_blank">Full Report</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <ul className="pl-4 arrowsList">
                                            <li><b>Black Swan Event Surveys</b></li>
                                        </ul>
                                        <div className="row mb-4">
                                            <div className="col-6 col-md-3 text-center">
                                                <Donut
                                                    diameter={80}
                                                    min={0}
                                                    max={100}
                                                    step={0}
                                                    value={100}
                                                    onValueChange=""
                                                    theme={{
                                                        donutColor: '#db9a36',
                                                        donutThickness: "15"
                                                    }}
                                                >
                                                </Donut>

                                            </div>
                                            <div className="col-sm-8 text-left">
                                                <p><small>View or Edit Tyson International Survey Answers</small></p>
                                                <a style={{ color: '#000000', backgroundColor: '#68cdf1' }} className="px-2 py-1 rounded" href="" onClick={handleClick}>Full Report</a>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 col-md-3 text-center">
                                                <Donut
                                                    diameter={80}
                                                    min={0}
                                                    max={100}
                                                    step={1}
                                                    value={83}
                                                    onValueChange=""
                                                    theme={{
                                                        donutColor: '#50b9f9',
                                                        donutThickness: "15"
                                                    }}
                                                >
                                                </Donut>

                                            </div>
                                            <div className="col-sm-8 text-left">
                                                <p><small>View Supplier Survey Answers</small></p>
                                                <a style={{ color: '#000000', backgroundColor: '#68cdf1' }} className="px-2 py-1 rounded" href="http://blackswan.developing.co/?report=1" target="_blank">Full Report</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6  col-sm-6 grid-margin stretch-card">
                            <div className="card card-statistics">
                                <div className="card-header  blue-card-header">Intelligence & Research</div>
                                <div className="card-body listType">
                                    <ul className="pl-4 arrowsList">
                                        <li>
                                            <Link to={{
                                                pathname: "/client-specific",
                                            }}>Client Specific</Link>
                                        </li>
                                        <li>
                                            <Link to={{
                                                pathname: "file-reader",
                                                state: {
                                                    name: "./files/1586887421_CocaoCoffee_COVID-19_Weather_Intel.pdf"
                                                }
                                            }}>Weather Insights</Link></li>

                                        <li>
                                        <Link
                                       
                                        to={{
                                            pathname: "map",
                                            state: {
                                                name: "./files/1586887421_CocaoCoffee_COVID-19_Weather_Intel.pdf"
                                            }
                                        }}>Cocoa, Coffee, COVID-19</Link>
                                        </li>

                                        <li>
                                            <a href="#" target="_self">Social Media Exploitation</a>
                                        </li>
                                        <li>
                                            <a href="./files/CHTCS-Journal-Winter-2020-Final.pdf" target="_blank">Winter 2020 Quarterly Journal</a>
                                        </li>


                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6  col-sm-6 grid-margin stretch-card">
                            <div className="card card-statistics">
                                <div className="card-header  blue-card-header">News Feed</div>
                                <div className="card-body listType">
                                    <ul className="pl-4 arrowsList">
                                    <li>
                                            <a href="./files/fla-guidance-covid-safeguards_0.pdf" target="_blank">
                                            FLA COVID-19 Pandemic Guidance
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.verite.org/covid-19-in-the-coffee-sector/" target="_blank">
                                                COVID-19 in the Coffee Sector: Challenges for Workers and Farmers
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://news.yahoo.com/us-sanctions-worlds-top-surgical-glove-maker-over-002421917.html" target="_blank">
                                                US sanctions world's top surgical glove maker over forced labour
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.hstoday.us/subject-matter-areas/airport-aviation-security/cabin-crew-to-be-better-equipped-to-prevent-human-trafficking/" target="_blank">
                                                Cabin Crew to be Better Equipped to Prevent Human Trafficking
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.reuters.com/article/india-trafficking/india-asks-local-leaders-to-boost-anti-trafficking-drive-amid-virus-threat-idUSL3N2EM2G0" target="_blank">
                                                India asks local leaders to boost anti-trafficking drive amid virus threat
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.forbes.com/sites/brucegoldfarb/2020/07/17/shareholder-engagement-esg/#3e95c7382c2d" target="_blank">
                                                Companies Need To Engage On ESG Issues Now Or Risk A Bruising 2021
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="modal-default" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">GRAT <b>Custom Solutions</b></h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="onClose">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.onSubmit}>
                                    <div className="form-group">
                                        <p>
                                            Schedule time with our experts to discuss your custom solution requirements:
                                           </p>
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control" rows="5" placeholder="Brief Summary"></textarea>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-block btn-secondary btn-lg">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default Dashboard;